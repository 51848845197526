import React, {useEffect} from 'react';

import './styles.scss';
import {DataPoint, GroupedData, View} from '../../App';
import InstitutionButton from '../InstitutionButton';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {isEmpty} from '../LinkDetail';

interface SearchBarProps {
  searchValue: string;
  setSearchValue: (code: string) => void;
  groupedData: GroupedData;
  hideResults?: boolean;
  setFilteredCity: (city: string | null) => void;
  setHoveredCity: (city: string | null) => void;
}

const SearchBar = ({
  searchValue,
  setSearchValue,
  groupedData,
  hideResults = false,
  setFilteredCity,
  setHoveredCity,
}: SearchBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const {view} = params;

  useEffect(() => {
    // Reset search value on URL change
    setSearchValue('');
  }, [location.pathname]);

  const openInstitutionDetail = (data: DataPoint) => {
    const {
      Country: countryName,
      Institution_name: institutionName,
      CN_Institution_name: cnInstitutionName,
      Institution_city: institutionCity,
      CN_Institution_city: cnInstitutionCity,
    } = data;
    // eslint-disable-next-line max-len
    setHoveredCity(null);
    navigate(`/map/${view}/${view === 'eu' ? countryName : 'China'}/${view === 'eu' ? institutionName : cnInstitutionName}`);
    setTimeout(() => {
      const city = view === 'eu' ? institutionCity : cnInstitutionCity;
      if (!isEmpty(city)) {
        setFilteredCity(city);
      }
    }, 100);
  };

  const filteredInstitutions = Object.keys(groupedData).map((city) => {
    const cityInstitutions = groupedData[city];
    return (
      <div key={city}>
        <label>{city}</label>
        <div className="group">
          {Object.keys(cityInstitutions.institutions).map((institutionName) => {
            const dataPoints = cityInstitutions.institutions[institutionName];
            return (
              <InstitutionButton
                key={dataPoints[0].Institution_name}
                data={dataPoints[0]}
                count={dataPoints.length}
                onClick={(data) => openInstitutionDetail(data)}
                view={view as View}
                setHoveredCity={() => {}}
              />
            );
          })}
        </div>
      </div>
    );
  });

  return (
    <div className="SearchBar">
      <div>
        <div className="search-icon">
          <svg width="512px" height="512px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M500.284,443.716l-81.839-81.839c26.117-37.251,41.481-82.592,41.555-131.523c0.189-125.3-100.912-228.299-226.196-230.323  C103.766-2.07-2.07,103.767,0.031,233.804C2.055,359.088,105.054,460.188,230.354,460c48.931-0.074,94.272-15.438,131.523-41.555  c0,0,81.839,81.839,81.839,81.839c15.621,15.621,40.948,15.621,56.568,0h0C515.905,484.663,515.905,459.337,500.284,443.716z   M230,380c-82.71,0-150-67.29-150-150S147.29,80,230,80s150,67.29,150,150S312.71,380,230,380z"/></svg>
        </div>
        <input
          type="text"
          value={searchValue}
          placeholder={`Search institutions in ${view === 'eu' ? 'Europe' : 'China'}`}
          onChange={(e) => setSearchValue(e.target.value)}
          onBlur={() => setSearchValue('')}
        />
        <div>
          {!hideResults &&
          searchValue.length > 1 &&
          filteredInstitutions.length > 0 ? (
            <div className="results">{filteredInstitutions}</div>
          ) : (
            !hideResults &&
            searchValue.length > 1 && (
              <div className="results no-results">No results</div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
