import React from 'react';
import countryList from 'react-select-country-list';

import ReactFlagsSelect from 'react-flags-select';
import geoJson from '../../maps/europe.json';

import './styles.scss';

interface CountrySelectProps {
  country: string;
  setCountry: (code: string) => void;
  availableCountries: Map<string, boolean>;
}

const COUNTRIES_MAP_FIX: {
  [key: string]: string;
} = {
  'Czech Republic': 'Czechia',
};

const CountrySelect = ({country, setCountry, availableCountries}: CountrySelectProps) => {
  // Some countries form the topojson have different names in the countryList
  // this is how we fix them
  // console.log(geoJson);
  const _country = COUNTRIES_MAP_FIX[country] || country;
  // const selectRef = useRef();

  const focusInput = (e: any) => {
    setTimeout(() => {
      const input: HTMLInputElement =
        document.body.querySelector('input[name="rfs-q"]');
      if (input) {
        input.focus();
      }
    }, 100);
  };

  return (
    <div onClick={focusInput}>
      <ReactFlagsSelect
        selected={_country ? countryList().getValue(_country) : null}
        searchable
        className="CountrySelect"
        searchPlaceholder="Search country"
        countries={geoJson.objects.europe.geometries.filter((geo) => availableCountries.get(geo.properties.NAME)).map((geo) => geo.id)}
        onSelect={(code) => {
          const name = countryList().getLabel(code);
          setCountry(name);
        }}
      />
    </div>
  );
};

export default CountrySelect;
