import React from 'react';
import './Loader.scss';

export default function Loader() {
  return (
    <div className='Loader'>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
}
