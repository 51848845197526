import React from 'react';
import {BiArrowBack} from 'react-icons/bi';
import {useNavigate} from 'react-router-dom';
import Logo from '../../components/Logo';

export interface ContactPageProps {}

const Contact = ({}: ContactPageProps) => {
  const navigate = useNavigate();
  return <div className="subpage">
    <Logo/>
    <div className="subpage-header">
      <BiArrowBack onClick={() => {
        navigate('/');
      }} className="back-button" /><h1>Contact</h1>
    </div>
  </div>;
};

export default Contact;
