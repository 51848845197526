import React from 'react';
import {NavLink} from 'react-router-dom';

import './styles.scss';

const Logo = () => {
  return (
    <NavLink to="/" className="Logo">
      <img src="/ceias_logo_icon.png" alt="CEIAS"/>
      <div className='desktop'>
        <span>China-Europe</span><br/><span>Academic Engagement Tracker</span>
      </div>
    </NavLink>
  );
};

export default Logo;
