import React from 'react';
import {
  Al,
  Am,
  At,
  Az,
  By,
  Be,
  Ba,
  Bg,
  Cn,
  Hr,
  Cy,
  Cz,
  Dk,
  Ee,
  Fi,
  Fr,
  Ge,
  De,
  Gi,
  Gr,
  Hu,
  Is,
  Id,
  Ie,
  Il,
  It,
  Jo,
  Kz,
  Kg,
  Lv,
  Li,
  Lt,
  Lu,
  Mk,
  Mt,
  Md,
  Mc,
  Me,
  Nl,
  No,
  Pl,
  Pt,
  Ro,
  Ru,
  Sm,
  Rs,
  Sk,
  Si,
  Es,
  Se,
  Ch,
  Tj,
  Tr,
  Tm,
  Ua,
  Gb,
  Uz,
  Ye,
} from 'react-flags-select';

const flagComponents: {[country: string]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element} = {
  'Albania': Al,
  'Armenia': Am,
  'Austria': At,
  'Azerbaijan': Az,
  'Belarus': By,
  'Belgium': Be,
  'Bosnia and Herzegovina': Ba,
  'Bulgaria': Bg,
  'China': Cn,
  'Croatia': Hr,
  'Cyprus': Cy,
  'Czech Republic': Cz,
  'Denmark': Dk,
  'Estonia': Ee,
  'Finland': Fi,
  'France': Fr,
  'Georgia': Ge,
  'Germany': De,
  'Gibraltar': Gi,
  'Greece': Gr,
  'Hungary': Hu,
  'Iceland': Is,
  'Indonesia': Id,
  'Ireland': Ie,
  'Israel': Il,
  'Italy': It,
  'Jordan': Jo,
  'Kazakhstan': Kz,
  'Kyrgyzstan': Kg,
  'Latvia': Lv,
  'Liechtenstein': Li,
  'Lithuania': Lt,
  'Luxembourg': Lu,
  'Republic of Macedonia': Mk,
  'Malta': Mt,
  'Republic of Moldova': Md,
  'Monaco': Mc,
  'Montenegro': Me,
  'Netherlands': Nl,
  'Norway': No,
  'Poland': Pl,
  'Portugal': Pt,
  'Romania': Ro,
  'Russia': Ru,
  'San Marino': Sm,
  'Serbia': Rs,
  'Slovakia': Sk,
  'Slovenia': Si,
  'Spain': Es,
  'Sweden': Se,
  'Switzerland': Ch,
  'Tajikistan': Tj,
  'Turkey': Tr,
  'Turkmenistan': Tm,
  'Ukraine': Ua,
  'United Kingdom': Gb,
  'Uzbekistan': Uz,
  'Yemen': Ye,
};

interface CountryFlagProps {
  country: string;
}

export default function CountryFlag({country}: CountryFlagProps) {
  const Flag = flagComponents[country];
  return <Flag className="CountryFlag"/>;
}
