import React from 'react';

import './styles.scss';
import {DataPoint} from '../../App';
import {useNavigate, useParams} from 'react-router-dom';
import {isEmpty} from '../LinkDetail';
import CountryFlag from '../CountryFlag/CountryFlag';

interface InstitutionDetailProps {
  data: DataPoint[];
}

const InstitutionDetail = ({data}: InstitutionDetailProps) => {
  // Grouped by type for EU view, grouped by country for CN view
  const groupedLinks: {
    [key: string]: DataPoint[];
  } = {};

  const navigate = useNavigate();
  const params = useParams();
  const {view} = params;

  data.forEach((dataPoint) => {
    const type = view === 'eu' ? dataPoint.CN_Institution_type : dataPoint.Country;
    if (!groupedLinks[type]) {
      groupedLinks[type] = [];
    }
    groupedLinks[type].push(dataPoint);
  });

  const openLinkDetail = (data: DataPoint) => {
    const {
      Country: countryName,
      Institution_name: institutionName,
      CN_Institution_name: cnInstitutionName,
    } = data;
    navigate(
        `/map/${view}/${
        view === 'eu' ? countryName : 'China'
        }/${encodeURIComponent(view === 'eu' ? institutionName : cnInstitutionName)}/${encodeURIComponent(
            view === 'eu' ? cnInstitutionName : institutionName,
        )}`,
    );
  };

  if (!data[0]) return <div>Loading...</div>;

  return (
    <div className={`InstitutionDetail`}>
      {view === 'eu' ? (
        <>
          <h1>{data[0].Institution_name}</h1>
          <p className="city-name">{isEmpty(data[0].Institution_city) ? 'N/A' : data[0].Institution_city}, {data[0].Country}</p>

          <div>
            <label>Chinese links</label>
            {Object.keys(groupedLinks).map((type) => {
              const data = groupedLinks[type];
              return (
                <div key={type} className="type-group">
                  <p className="type-name">{type}</p>
                  {data.map((link) => {
                    return (
                      <div key={link.CN_Institution_name}>
                        <a onClick={() => openLinkDetail(link)}>
                          {link.CN_Institution_name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <h1>{data[0].CN_Institution_name}</h1>
          <p className="city-name">{isEmpty(data[0].CN_Institution_city) ? 'N/A' : data[0].CN_Institution_city}, China</p>

          <div>
            <label>European links</label>
            {Object.keys(groupedLinks).map((type) => {
              const data = groupedLinks[type];
              return (
                <div key={type} className="type-group">
                  <p className="type-name">
                    {view === 'cn' && <CountryFlag country={type}/>}
                    <span>{type}</span>
                  </p>
                  {data.map((link) => {
                    return (
                      <div key={link.Institution_name}>
                        <a onClick={() => openLinkDetail(link)}>
                          {link.Institution_name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default InstitutionDetail;
