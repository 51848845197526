import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {EntryCollection} from 'contentful';
import React, {useEffect, useMemo, useState} from 'react';
import {BiArrowBack} from 'react-icons/bi';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import Footer from '../../components/Footer';
import HeaderPanel from '../../components/HeaderPanel';
import Loader from '../../components/Loader/Loader';
import contentfulClient from '../../utils/contentful';

export interface ArticlesPageProps {}

const Articles = ({}: ArticlesPageProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const {id} = params;
  const [loading, setLoading] = useState(true);
  const [entriesCollection, setEntriesCollection] =
    useState<EntryCollection<any>>();

  useEffect(() => {
    setLoading(true);
    contentfulClient
        .getEntries({
          content_type: 'article',
        })
        .then((response) => {
          setEntriesCollection(response);
          setLoading(false);
        // console.log(response);
        });
  }, []);

  const articleDetail = useMemo(() => {
    if (id) {
      return entriesCollection?.items.find((article) => article.sys.id === id);
    } else {
      return null;
    }
  }, [entriesCollection, id]);

  const renderAuthor = (author: any) => {
    if (author) {
      return (
        <div className="author">
          <div>
            <div
              className="author-pic"
              style={{
                backgroundImage: `url(${author.fields.memberPicture.fields.file.url})`,
              }}
            ></div>
          </div>
          <div>
            <p>{author.fields.title}</p>
            <p>{author.fields.name}</p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <HeaderPanel />
      <div className="subpage">
        <div className="subpage-header">
          <BiArrowBack
            onClick={() => {
              navigate(articleDetail ? '/articles' : '/');
            }}
            className="back-button"
          />
          <h1>Articles</h1>
        </div>
        <div className="articles">
          {loading ? (
            <Loader />
          ) : (
            <>
              {articleDetail ? (
                <div className="article-detail">
                  <div
                    className="pic"
                    style={{
                      backgroundImage: `url(${articleDetail?.fields?.thumbnail?.fields?.file.url})`,
                    }}
                  ></div>
                  <div className="content">
                    <h1>{articleDetail?.fields.title}</h1>
                    {documentToReactComponents(articleDetail?.fields?.content)}
                    <span>Authors</span>
                    <div className="authors">
                      {articleDetail?.fields.authors.map((author: any) =>
                        renderAuthor(author),
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {entriesCollection.items.map((article) => {
                    return (
                      <div className="article-thumb" key={article.fields.title}>
                        <NavLink to={`/articles/${article.sys.id}`}>
                          <div
                            className="pic"
                            style={{
                              backgroundImage: `url(${article.fields.thumbnail.fields.file.url})`,
                            }}
                          >
                            <h2 className="title">{article.fields.title}</h2>
                          </div>
                        </NavLink>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Articles;
