/* eslint-disable max-len */
import React from 'react';
import NavigationLinks from '../NavigationLinks';
import SocialIcons from '../SocialIcons';

import './styles.scss';

const Footer = () => {
  return (
    <div className="Footer">
      <div>
        <div className="copyright">
          <span>Made by <a href="https://ceias.eu" target="_blank" rel="noreferrer">CEIAS</a> in 2022</span>
        </div>
        <div className="navigation">
          <NavigationLinks/>
        </div>
        <div>
          <SocialIcons/>
        </div>
      </div>
    </div>
  );
};

export default Footer;
