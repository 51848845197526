import React, {useEffect, useState} from 'react';
import {BiArrowBack} from 'react-icons/bi';
import {useNavigate} from 'react-router-dom';
import Footer from '../../components/Footer';
import HeaderPanel from '../../components/HeaderPanel';
import Loader from '../../components/Loader/Loader';
import contentfulClient from '../../utils/contentful';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {Entry} from 'contentful';

export interface AboutPageProps {}

const About = ({}: AboutPageProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState<Entry<any>>();

  useEffect(() => {
    setLoading(true);
    contentfulClient
        .getEntry('6hTmDzJ37sHFwHUAoF64WC')
        .then((entry) => {
          // console.log(entry);
          setLoading(false);
          setDocument(entry);
        })
        .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <HeaderPanel/>
      <div className="subpage">
        <div className="subpage-header">
          <BiArrowBack
            onClick={() => {
              navigate('/');
            }}
            className="back-button"
          />
          <h1>About</h1>
        </div>
        { loading ? (
          <Loader/>
        ) : (
          <div className="columns">
            <div className="col">
              {documentToReactComponents(document?.fields?.content)}
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default About;
