import React from 'react';
import {BiArrowBack} from 'react-icons/bi';
import {useNavigate} from 'react-router-dom';
import Footer from '../../components/Footer';
import HeaderPanel from '../../components/HeaderPanel';

export interface CrowdsourcingPageProps {}

const Crowdsourcing = ({}: CrowdsourcingPageProps) => {
  const navigate = useNavigate();
  return (
    <div className="subpage">
      <HeaderPanel/>
      <div className="subpage-header">
        <BiArrowBack
          onClick={() => {
            navigate('/');
          }}
          className="back-button"
        />
        <h1>Crowdsourcing</h1>
      </div>
      <div>
        <iframe
          className="google-form-iframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLSc9xHqFkn7ez4frz_pGugPGHg6EtPy5u6JFVEqf8GTMQDncEA/viewform?embedded=true"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
        >
          Loading…
        </iframe>
      </div>
      <Footer />
    </div>
  );
};

export default Crowdsourcing;
