import React, {useMemo} from 'react';

import './styles.scss';
import {DataPoint, GroupedData, View} from '../../App';
import {useNavigate, useParams} from 'react-router-dom';
import InstitutionButton from '../InstitutionButton';
import {isEmpty} from '../LinkDetail';

interface CountryDetailProps {
  filteredCity: string | null;
  groupedData: GroupedData;
  showDisclaimer: boolean;
  searchValue: string;
  country: string;
  setHoveredCity: (city: string | null) => void;
}

const CountryDetail = ({
  filteredCity,
  groupedData,
  showDisclaimer,
  country,
  setHoveredCity,
}: CountryDetailProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const {view} = params;

  const openInstitutionDetail = (data: DataPoint) => {
    const {Country: countryName} = data;
    // eslint-disable-next-line max-len
    const institutionName = view === 'eu' ? data.Institution_name : data.CN_Institution_name;
    // eslint-disable-next-line max-len
    navigate(`/map/${view}/${view === 'eu' ? countryName : 'China'}/${institutionName}`);
  };

  const countryGroupedData = useMemo(() => {
    const data: GroupedData = {};
    Object.keys(groupedData).forEach((city) => {
      const cityInstitutions = groupedData[city];
      if (view === 'cn') {
        data[city] = cityInstitutions;
      }
      if (cityInstitutions.country === country) {
        data[city] = cityInstitutions;
      }
    });
    return data;
  }, [groupedData, country]);

  return (
    <div className="CountryDetail">
      {Object.keys(countryGroupedData).map((city) => {
        const cityInstitutions = groupedData[city];
        return (
          <div key={city}>
            <div className="group" id={`group-${city}`}>
              <label>{isEmpty(city) ? 'N/A' : city}</label>
              {Object.keys(cityInstitutions.institutions).sort((a, b) => {
                // Sort institutions by number of links and then alphabetically
                const countDiff = cityInstitutions.institutions[b]?.length - cityInstitutions.institutions[a]?.length;
                if (countDiff === 0) {
                  return a.localeCompare(b);
                } else {
                  return countDiff;
                }
              }).map((institutionName) => {
                const dataPoints = cityInstitutions.institutions[institutionName];
                return (
                  <InstitutionButton
                    key={
                      view === 'eu' ?
                        dataPoints[0].Institution_name :
                        dataPoints[0].CN_Institution_name
                    }
                    view={view as View}
                    data={dataPoints[0]}
                    count={dataPoints.length}
                    hovered={filteredCity === city}
                    setHoveredCity={setHoveredCity}
                    onClick={(data) => openInstitutionDetail(data)}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CountryDetail;
