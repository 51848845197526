import {EntryCollection} from 'contentful';
import React, {useEffect, useState} from 'react';
import {BiArrowBack} from 'react-icons/bi';
import {useNavigate} from 'react-router-dom';
import Footer from '../../components/Footer';
import HeaderPanel from '../../components/HeaderPanel';
import Loader from '../../components/Loader/Loader';
import contentfulClient from '../../utils/contentful';

export interface TeamPageProps {}

const Team = ({}: TeamPageProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [entriesCollection, setEntriesCollection] = useState<EntryCollection<any>>();

  useEffect(() => {
    setLoading(true);
    contentfulClient
        .getEntries({
          content_type: 'team',
        })
        .then((response) => {
          setEntriesCollection(response);
          setLoading(false);
          // console.log(response);
        });
  }, []);

  const sortedMembers = entriesCollection?.items.slice() || [];
  sortedMembers.sort((a, b) => {
    return (a.fields?.order || 0) - (b.fields?.order || 0);
  });

  const groupedMembers: { [country: string]: any[] } = {};
  sortedMembers.forEach((member) => {
    // console.log(member.fields);
    member.fields.country?.forEach((country: string) => {
      if (!groupedMembers[country]) {
        groupedMembers[country] = [];
      }
      groupedMembers[country].push(member);
    });
  });

  return <div className="subpage">
    <HeaderPanel/>
    <div className="subpage-header">
      <BiArrowBack onClick={() => {
        navigate('/');
      }} className="back-button" /><h1>Team</h1>
    </div>
    { loading ? (
          <Loader/>
        ) : (
          <div className="country-groups">
            {Object.keys(groupedMembers).map((key) => {
              return (
                <div key={key}>
                  <h2>{key}</h2>
                  <div className="team-members">
                    {
                      groupedMembers[key]?.map((item) => {
                        const memberPicUrl = item.fields.memberPicture?.fields?.file.url;
                        return (
                          <div key={item.fields.name}>
                            <div
                              className="pic"
                              style={{
                                backgroundImage: memberPicUrl ? `url(${memberPicUrl})` : 'url(//images.ctfassets.net/bk2zr7lx2frq/1oJJxWGNgxD986x6LApEBz/b3e8096d9d48b364b04838f06aecf409/istockphoto-1300845620-612x612.jpg)',
                              }}
                            ></div>
                            <p className="name">{item.fields.name}</p>
                            <p className="title">{item.fields.title}</p>
                            <a href="mailto:simalcik@ceias.eu">{item.fields.email}</a>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })}
          </div>
        )}

    <Footer />
  </div>;
};

export default Team;
