import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from 'react-simple-maps';
import {Motion, spring} from 'react-motion';

import './styles.scss';


import geoJsonEurope from '../../maps/europe.json';
import geoJsonChina from '../../maps/china.json';

import {geoPath, geoMercator, GeoProjection} from 'd3-geo';
import {DataPoint, View} from '../../App';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {feature} from 'topojson-client';

export interface Marker {
  city?: string;
  institution?: string;
  coords: [number, number];
  children?: Marker[];
}

interface MapChartProps {
  country: string | null;
  filteredCity: string | null;
  hoveredCity: string | null;
  setCountry: (code: string | null) => void;
  setFilteredCity: (city: string | null) => void;
  data: DataPoint[];
  view?: View;
  setTooltipContent: (tooltip: string) => void;
  availableCountries: Map<string, boolean>;
}

const PROJECTION_ROTATE_EU: [number, number, number] = [-20.0, -80.0, 0];
const PROJECTION_ROTATE_CHINA: [number, number, number] = [-40.0, -15.0, -40];

// Center of Europe map
const INIT_CENTER_EU = [18, 50];
// Center of China
const INIT_CENTER_CHINA = [104, 35];
const SCALE = 900;

const MapChart = ({
  country,
  setCountry,
  filteredCity,
  hoveredCity,
  setFilteredCity,
  data,
  view = 'eu',
  setTooltipContent,
  availableCountries,
}: MapChartProps) => {
  const navigate = useNavigate();
  const DEFAULT_ZOOM = window.innerWidth < 767 ? 2 : 1;
  const [animated, setAnimated] = useState(false);
  const [hoveredCountry, setHoveredCountry] = useState('');
  const [scaleFactor, setScaleFactor] = useState<number>(DEFAULT_ZOOM);
  const [zoomableProps, setZoomableProps] = useState({
    zoom: DEFAULT_ZOOM,
    center: view === 'eu' ? INIT_CENTER_EU : INIT_CENTER_CHINA,
  });

  const groupedMarkers: { [key: string]: Marker[] } = {};


  const transformedMarkers = useMemo(() => {
    const markers: Marker[] = [];

    data.forEach((dataPoint) => {
      // eslint-disable-next-line max-len
      const groupKey = (view === 'eu' ? dataPoint.coords : dataPoint.coords_cn).join(',');
      groupedMarkers[groupKey] = (groupedMarkers[groupKey] || []).concat({
        institution: dataPoint.Institution_name,
        coords: view === 'eu' ? dataPoint.coords : dataPoint.coords_cn,
        city:
          view === 'eu' ?
            dataPoint.Institution_city :
            dataPoint.CN_Institution_city,
      });
    });

    Object.keys(groupedMarkers).forEach((groupKey) => {
      let marker: Marker = null;
      if (groupedMarkers[groupKey].length === 1) {
        marker = groupedMarkers[groupKey][0];
      } else {
        marker = {
          coords: groupedMarkers[groupKey][0].coords,
          city: groupedMarkers[groupKey][0].city,
          children: [],
        };

        groupedMarkers[groupKey].forEach((groupMarker, i) => {
          marker.children.push(groupMarker);
        });
      }

      markers.push(marker);
    });

    // Move filtered city to the end of the list
    // to make it display last (because z-index doesn't work with svg)
    const focusedCity = filteredCity || hoveredCity;
    if (focusedCity) {
      const filteredCityIndex = markers.findIndex((marker) => marker.city === focusedCity);
      const filteredCityMarker = markers.splice(filteredCityIndex, 1);
      markers.push(filteredCityMarker[0]);
    }
    return markers;
  }, [groupedMarkers, view, filteredCity, hoveredCity]);

  const isAvailable = useCallback((country: string) => {
    return availableCountries.get(country);
  }, [availableCountries]);

  useEffect(() => {
    if (!country) {
      setAnimated(true);
      setZoomableProps({
        center: view === 'eu' ? INIT_CENTER_EU : INIT_CENTER_CHINA,
        zoom: DEFAULT_ZOOM,
      });
      setScaleFactor(DEFAULT_ZOOM);
      return null;
    } else if (view === 'cn') {
      return null;
    }

    setAnimated(true);

    const geometry = geoJsonEurope.objects.europe.geometries.find(
        (geo) => geo.properties.NAME === country,
    );
    // TS complaining if casted as Topology object
    // console.log('g', geometry);
    const geo = feature(geoJsonEurope as any, geometry as any);
    // const geo = geometry as GeoJSON.Feature;
    // console.log('geo', geo);
    const path = geoPath().projection(projection());
    const centroid = projection().invert(path.centroid(geo));

    // calculate zoom level
    const bounds = path.bounds(geo);
    const dx = bounds[1][0] - bounds[0][0];
    const dy = bounds[1][1] - bounds[0][1];

    const size = (dx + dy) / 1.5;
    const zoom = Math.max(400 / size, 1);

    const drawerOffset = [12 / zoom, 0];

    const centroidOffset: [number, number] = [
      centroid[0] + drawerOffset[0],
      centroid[1] + drawerOffset[1],
    ];


    // Wrapped in set timeout for it to work on page refresh
    setTimeout(() => {
      setZoomableProps({
        ...zoomableProps,
        zoom,
        center: centroidOffset,
      });
      setScaleFactor(zoom);
    }, 0);
  }, [country]);

  useEffect(() => {
    // const marker = groupedMarkers[filteredCity];
    const markerEl = document.querySelector(`.map-marker[data-city="${filteredCity}"`);
    if (!markerEl) return;
    const viewportOffset = markerEl.getBoundingClientRect();
    // these are relative to the viewport, i.e. the window
    const markerLeftOffset = viewportOffset.left;

    // Only center on markers that would be covered by Drawer
    const isMarkerInRightViewHalf = markerLeftOffset > (window.innerWidth / 2);
    if (filteredCity) {
      setAnimated(true);
      const marker = transformedMarkers.find((marker) => marker.city === filteredCity);
      setZoomableProps({
        ...zoomableProps,
        center: [isMarkerInRightViewHalf ? Number(marker.coords[0]) : zoomableProps.center[0], Number(marker.coords[1])],
      });
    }
  }, [filteredCity]);

  useEffect(() => {
    setZoomableProps({
      center: view === 'eu' ? INIT_CENTER_EU : INIT_CENTER_CHINA,
      zoom: DEFAULT_ZOOM,
    });
    setAnimated(false);
    setScaleFactor(DEFAULT_ZOOM);
  }, [view]);

  const projection = (): GeoProjection => {
    return geoMercator()
        .rotate(view === 'eu' ? PROJECTION_ROTATE_EU : PROJECTION_ROTATE_CHINA)
        .scale(SCALE);
  };
  const handleClick = (geo: any) => {
    if (view === 'cn') return;
    if (!isAvailable(geo.properties.NAME)) return;
    setCountry(geo.properties.NAME);
  };

  const handleHover = (geo: any) => {
    setHoveredCountry(geo.properties.NAME);
  };

  const clickMarkerHandler = (marker: Marker) => {
    navigate(`/map/${view}/${view === 'eu' ? country : 'China'}`);
  };

  return (
    <div data-tip data-for="marker-tooltip">
      <Motion
        defaultStyle={{
          z: zoomableProps.zoom,
          x: zoomableProps.center[0],
          y: zoomableProps.center[1],
          w: scaleFactor,
        }}
        style={{
          z: animated ? spring(zoomableProps.zoom) : zoomableProps.zoom,
          x: animated ? spring(zoomableProps.center[0]) : zoomableProps.center[0],
          y: animated ? spring(zoomableProps.center[1]) : zoomableProps.center[1],
          w: animated ? spring(scaleFactor) : scaleFactor,
        }}
        onRest={() => setAnimated(false)}
      >
        {({z, x, y, w}) => (
          <ComposableMap
            projection="geoMercator"
            className="MapChart"
            key={view}
            projectionConfig={{
              rotate:
              view === 'eu' ? PROJECTION_ROTATE_EU : PROJECTION_ROTATE_CHINA,
              scale: SCALE,
            }}
          >
            <ZoomableGroup
              zoom={z}
              center={[x, y]}
              maxZoom={15}
              onMove={({k}) => setScaleFactor(k)}
              onMoveEnd={(e) => {
                setZoomableProps({
                  zoom: e.zoom,
                  center: e.coordinates,
                });
              }}
            >
              <Geographies
                geography={view === 'eu' ? geoJsonEurope: geoJsonChina}
              >
                {({geographies}) =>
                  geographies.map((geo: any) => {
                    const isClicked =
                    view === 'eu' && country === geo.properties.NAME;
                    const isHovered =
                    view === 'eu' && hoveredCountry === geo.properties.NAME;
                    return (
                      <Geography
                        onClick={() => handleClick(geo)}
                        key={geo.rsmKey}
                        geography={geo}
                        fill={
                        !isAvailable(geo.properties.NAME) ? '#e1caca':
                        isClicked ?
                          '#d79696' :
                          isHovered ?
                          '#c89e9e' :
                          '#d0a8a8'
                        }
                        stroke="#fff"
                        strokeWidth={0.15}
                        onMouseOver={() => handleHover(geo)}
                        onMouseOut={() => setHoveredCountry('')}
                        style={{
                          default: {outline: 'none', cursor: 'pointer'},
                          hover: {outline: 'none', cursor: 'pointer'},
                          pressed: {outline: 'none'},
                        }}
                      />
                    );
                  })
                }
              </Geographies>
              {transformedMarkers.map((marker, i) => {
                const count = marker?.children ? marker.children?.length : 1;
                return (
                  <Marker
                    key={i}
                    coordinates={marker?.coords}
                    onClick={() => clickMarkerHandler(marker)}
                    onMouseEnter={() => {
                      setTooltipContent(marker?.city);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                    style={{
                      default: {
                        fontSize: `${10 / w}px`,
                      },
                      hover: {
                        fontSize: `${10 / w}px`,
                      },
                      pressed: {
                        fontSize: `${10 / w}px`,
                      },
                    }}
                  >
                    <g
                      className={classNames(['map-marker', {selected: hoveredCity === marker?.city || filteredCity === marker?.city}])}
                      data-city={marker?.city}
                      onClick={() => setFilteredCity(marker?.city === filteredCity ? null : marker?.city)}
                    >
                      <circle className="circle-glow" r={11.5 / w} />
                      <circle className="circle-inner" r={10 / w}/>
                      <text textAnchor="middle" dominantBaseline="central">
                        {count}
                      </text>
                    </g>
                  </Marker>
                );
              })}
            </ZoomableGroup>
          </ComposableMap>
        )}
      </Motion>
    </div>
  );
};

export default MapChart;
