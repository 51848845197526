import React, {useEffect, useState} from 'react';
import {DataPoint, GroupedData, View} from '../../App';
import {useNavigate, useParams} from 'react-router-dom';
import MapChart from '../../components/MapChart';
import Drawer from '../../components/Drawer';
// import SearchBar from '../../components/SearchBar';
import Footer from '../../components/Footer';
import HeaderPanel from '../../components/HeaderPanel';
import ReactTooltip from 'react-tooltip';
export interface MapPageProps {
  data: DataPoint[];
  availableCountries: Map<string, boolean>;
}

const MapPage = ({data, availableCountries}: MapPageProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const {country, institution, link, view} = params;
  const [filteredCity, setFilteredCity] = useState<string>();
  const [hoveredCity, setHoveredCity] = useState<string>();
  const [tooltipContent, setTooltipContent] = useState('');

  const castView = view as View;

  useEffect(() => {
    setFilteredCity(null);
  }, [searchValue]);


  useEffect(() => {
    const institution = params.institution;
    const dataPoint = data.find((dp) => {
      return (view === 'eu' ? dp.Institution_name : dp.CN_Institution_name) === institution;
    });
    if (dataPoint) {
      const filteredCity = view === 'eu' ? dataPoint.Institution_city : dataPoint.CN_Institution_city;
      setFilteredCity(filteredCity);
    }
  }, [data]);

  // Filtered by country
  const filteredData = data.filter(
      (dataPoint) => {
        if (view === 'cn') return true;
        return dataPoint.Country === country;
      },
  );

  // All data grouped by city (for searchbar)
  const groupedData: GroupedData = {};

  data.forEach((dataPoint) => {
    const city =
  view === 'eu' ?
    dataPoint.Institution_city :
    dataPoint.CN_Institution_city;
    const institutionName =
  view === 'eu' ?
    dataPoint.Institution_name :
    dataPoint.CN_Institution_name;
    if (!groupedData[city]) {
      groupedData[city] = {
        country: dataPoint.Country,
        institutions: {},
      };
    }
    if (!groupedData[city].institutions[institutionName]) {
      groupedData[city].institutions[institutionName] = [];
    }
    groupedData[city].institutions[institutionName].push(
        dataPoint,
    );
  });

  const goBack = () => {
    if (link) {
      setInstitution(institution);
    } else if (institution) {
      setCountry(country);
      setHoveredCity(null);
    } else if (country) {
      setFilteredCity(null);
      navigate(`/map/${view}`);
    }
  };

  const setCountry = (country: string) => {
    setFilteredCity(null);
    navigate(`/map/${view}/${country}`);
  };

  const setInstitution = (institution: string) => {
    navigate(`/map/${view}/${country}/${institution}`);
  };

  const setLink = (link: string) => {
    navigate(`/map/${view}/${country}/${institution}/${link}`);
  };


  return (
    <div className="mappage">
      <HeaderPanel
        setSearchValue={setSearchValue}
        setCountry={setCountry}
        searchValue={searchValue}
        setFilteredCity={setFilteredCity}
        setHoveredCity={setHoveredCity}
        data={data}/>
      <MapChart
        country={country}
        setCountry={setCountry}
        data={filteredData}
        filteredCity={filteredCity}
        hoveredCity={hoveredCity}
        setFilteredCity={setFilteredCity}
        setTooltipContent={setTooltipContent}
        view={castView}
        availableCountries={availableCountries}
      />
      {(tooltipContent && !filteredCity) && (
        <ReactTooltip id="marker-tooltip">{tooltipContent}</ReactTooltip>
      )}
      <Drawer
        country={country}
        institution={institution}
        link={link}
        setLink={setLink}
        setCountry={setCountry}
        setInstitution={setInstitution}
        groupedData={groupedData}
        data={data}
        filteredCity={filteredCity}
        setFilteredCity={setFilteredCity}
        setHoveredCity={setHoveredCity}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        goBack={goBack}
        availableCountries={availableCountries}
      />
      <div className="view-switch">
        <div>
          <p>Switch map view</p>
        </div>
        <div>
          <a
            href="#"
            className={view === 'eu' ? 'active' : ''}
            onClick={() => {
              setFilteredCity(null);
              setHoveredCity(null);
              navigate('/map/eu');
            }}
            style={{backgroundImage: `url(/europe-square.png)`}}
          >
            EU
          </a>
          <a
            href="#"
            className={view === 'cn' ? 'active' : ''}
            onClick={() => {
              setFilteredCity(null);
              setHoveredCity(null);
              navigate('/map/cn');
            }}
            style={{backgroundImage: `url(/china-square.png)`}}
          >
            CN
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MapPage;
