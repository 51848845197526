/* eslint-disable max-len */
import React, {useEffect, useMemo, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import './App.scss';
import About from './pages/About';
import Articles from './pages/Articles';
import Contact from './pages/Contact';
import Crowdsourcing from './pages/Crowdsourcing';
import MapPage from './pages/MapPage';
import Team from './pages/Team';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

export interface DataPoint {
  Country: string;
  coords: [number, number];
  coords_cn: [number, number];
  Institution_name: string;
  Institution_city: string;
  Latitude: string;
  Longitude: string;
  Institution_type: string;
  CN_Institution_name: string;
  CN_Institution_type: string;
  CN_Institution_city: string;
  CN_Latitude: string;
  CN_Longitude: string;
  Link_established?: string;
  Link_ended?: string;
  Link_type?: string;
  Financial_income?: string;
  NonFinancial_income?: string;
  Stipends?: string;
  Contract?: string;
  Contract_disclosed?: string;
  ASPI_risk?: string;
  ASPI_SecCred?: string;
  ASPI_Espionage?: string;
  ASPI_Sanctions?: string;
  Cooperation_areas?: string;
  Security_screening?: string;
  Other_remarks?: string;
  FOIA_Answer?: string;
  Contract_Link: string;
  [otherOptions: string]: unknown;
}

export interface NavigateMethods {
  setCountry: (country: string) => void;
  setInstitution: (institution: string) => void;
  setLink: (link: string) => void;
}

export interface GroupedData {
  [city: string]: {
    country: string;
    institutions: InstitutionsGroup
  };
}
export interface InstitutionsGroup {
  [name: string]: DataPoint[];
}

export type View = 'eu' | 'cn';


function App() {
  const [data, setData] = useState<DataPoint[]>([]);

  const availableCountries = useMemo(() => {
    const countries: {[country: string]: boolean} = {};
    data.forEach((dataPoint) => {
      if (!countries[dataPoint.Country]) {
        countries[dataPoint.Country] = true;
      }
    });
    return new Map(Object.keys(countries).map((key) => [key, true]));
  }, [data]);

  const mapPage = () => {
    return (
      <MapPage
        data={data}
        availableCountries={availableCountries}
      />
    );
  };

  useEffect(() => {
    fetch(
        `https://opensheet.elk.sh/1X6At1mDDp63HIX0ClSPpjiV1WK09Z9RHkmj3rqtY8sU/Combined`,
    )
        .then((res) => res.json())
        .then((data: DataPoint[]) => {
          const mappedData = data.map((row) => {
            const dataPoint: DataPoint = {
              ...row,
              coords: [Number(row.Longitude), Number(row.Latitude)],
              coords_cn: [Number(row.CN_Longitude), Number(row.CN_Latitude)],
            };
            return dataPoint;
          });
          setData(mappedData);
        });
  }, []);

  return (
    <div className="App">
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>China-Europe Academic Engagement Tracker</title>
        <meta
          name="description"
          content="A database of interactions between European academic institutions and Chinese entities. It was created by CEIAS and partners from investigated countries."
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemProp="name" content="China-Europe Academic Engagement Tracker" />
        <meta
          itemProp="description"
          content="A database of interactions between European academic institutions and Chinese entities. It was created by CEIAS and partners from investigated countries."
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="China-Europe Academic Engagement Tracker" />
        <meta property="og:image" content="https://academytracker.ceias.eu/academy-tracker.png" />
        <meta
          property="og:description"
          content="A database of interactions between European academic institutions and Chinese entities. It was created by CEIAS and partners from investigated countries."
        />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:title" content="China-Europe Academic Engagement Tracker"/>
        <meta name="twitter:description" content="A database of interactions between European academic institutions and Chinese entities. It was created by CEIAS and partners from investigated countries."/>
        <meta name="twitter:image" content="https://academytracker.ceias.eu/academy-tracker.png"/>
      </Helmet>
      <Routes>
        <Route path="/team" element={<Team/>} />
        <Route path="/crowdsourcing" element={<Crowdsourcing/>} />
        <Route path="/articles" element={<Articles/>} />
        <Route path="/articles/:id" element={<Articles/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/map/:view" element={mapPage()} />
        <Route path="/map/:view/:country" element={mapPage()} />
        <Route path="/map/:view/:country/:institution" element={mapPage()} />
        <Route path="/map/:view/:country/:institution/:link" element={mapPage()} />
        <Route path="*" element={<Navigate to="/map/eu" />} />
      </Routes>
      <CookieConsent style={{
        zIndex: 100000,
        left: 'auto',
        width: 'auto',
        right: 0,
        margin: '20px',
        borderRadius: '5px',
      }}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
