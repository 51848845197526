import React from 'react';
import CountrySelect from '../CountrySelect';
import {BiArrowBack} from 'react-icons/bi';
import './styles.scss';
import {DataPoint, GroupedData} from '../../App';
import InstitutionDetail from '../InstitutionDetail';
import CountryDetail from '../CountryDetail';
import classNames from 'classnames';
import LinkDetail from '../LinkDetail';
// import SearchBar from '../SearchBar';
import {useParams} from 'react-router-dom';

interface DrawerProps {
  country: string | null;
  institution: string | null;
  link: string | null;
  filteredCity: string | null;
  searchValue: string;
  setSearchValue: (value: string) => void;
  setFilteredCity: (city: string | null) => void;
  setHoveredCity: (city: string | null) => void;
  setCountry: (code: string) => void;
  setLink: (code: string) => void;
  setInstitution: (institutionName: string | null) => void;
  groupedData: GroupedData;
  data: DataPoint[];
  goBack: () => void;
  availableCountries: Map<string, boolean>;
}

const Drawer = ({
  country,
  institution,
  link,
  searchValue,
  setSearchValue,
  setInstitution,
  setLink,
  setCountry,
  groupedData,
  data,
  filteredCity,
  setFilteredCity,
  setHoveredCity,
  goBack,
  availableCountries,
}: DrawerProps) => {
  const params = useParams();
  const {view} = params;
  const linkData = data.filter((d) => {
    return (view === 'eu' ? d.CN_Institution_name : d.Institution_name) === link &&
    (view === 'eu' ? d.Institution_name : d.CN_Institution_name) === institution;
  });

  return (
    <div
      className={classNames([
        'Drawer',
        {hidden: !country},
      ])}
      key={view}
    >
      <div className="anchored-header">
        <div className="back-header" onClick={() => goBack()} >
          <BiArrowBack className="back-button" />
          <p className="back-button-text">Back</p>
        </div>
        {view === 'eu' && (
          <CountrySelect country={country} setCountry={setCountry} availableCountries={availableCountries}/>
        )}
      </div>
      <div className={classNames(['scrollable-content', {'no-country-select': view === 'cn'}])}>
        {link ? (
          <LinkDetail
            data={linkData}
          />
        ) : institution ? (
          <InstitutionDetail
            data={data.filter((d) => (view === 'eu' ? d.Institution_name : d.CN_Institution_name) === institution)}
          />
        ) : (
          <CountryDetail
            searchValue={searchValue}
            filteredCity={filteredCity}
            setHoveredCity={setHoveredCity}
            country={country}
            groupedData={filteredCity ? {[filteredCity]: groupedData[filteredCity]} : groupedData}
            showDisclaimer={country === 'Germany' || country === 'Austria'}
          />
        )}
      </div>
    </div>
  );
};

export default Drawer;
