import {createClient} from 'contentful';

const contentfulClient = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: 'bk2zr7lx2frq',
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: 'uQQIVCb_vyNSSCgslDPi3rYUP48XtRZ0JnGmlJdL93o',
});

export default contentfulClient;
