import React from 'react';

import './styles.scss';
import {DataPoint, View} from '../../App';

interface InstitutionButtonProps {
  data: DataPoint;
  count: number;
  onClick: (data: DataPoint) => void;
  hovered?: boolean;
  view: View,
  setHoveredCity: (city: string | null) => void;
}

const InstitutionButton = ({
  data,
  count,
  onClick,
  hovered = false,
  view,
  setHoveredCity,
}: InstitutionButtonProps) => {
  return (
    <div
      className={`institution-button${hovered ? ' hovered' : ''}`}
      onClick={() => onClick(data)}
      onMouseDown={(e) => e.preventDefault()}
      onMouseEnter={() => setHoveredCity(view === 'eu' ? data.Institution_city : data.CN_Institution_city)}
      onMouseLeave={() => setHoveredCity(null)}
    >
      <span className="marker-icon">{count}</span>
      <span>
        {view === 'eu' ? data.Institution_name : data.CN_Institution_name}
      </span>
    </div>
  );
};

export default InstitutionButton;
